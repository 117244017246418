import React from 'react';

class TwitchVideo extends React.Component {

    render() {
        return (
            <div class="md:container lg:max-w-screen-md">
                <div id="twitch-embed" class="h-64 lg:h-96">
                    <iframe
                        src="https://embed.twitch.tv/?channel=ruhdacted&parent=ruhdacted.com&muted=true&layout=video"
                        height="100%"
                        width="100%"
                        allowfullscreen="true"
                        title="twitch-embed-player">
                    </iframe>
                </div>
            </div>
            
        );
    }
}

export default TwitchVideo;