// import logo from './logo.svg';
import './App.css';
import React from 'react';
import TwitchVideo from './TwitchVideo';
// import { Button } from 'antd';
// import { Card } from 'antd';

function Title(props) {
  return <h1 class="px-4 py-4 bg-black italic">{props.name}</h1>;
}
class PageHeader extends React.Component {
  render() {
    return (
      <div class="bg-gray-700">
        <div class="flex flex-row-reverse items-center justify-between h-16">
          <div className="overflow-hidden px-4">
            <a href="https://twitch.tv/ruhdacted" target="tab">
              <img
                  className="inline-block h-10 w-10 rounded-full ring-white"
                  src="https://static-cdn.jtvnw.net/jtv_user_pictures/aeb0ad42-7f62-4c6e-a13a-6e2fb9641d71-profile_image-70x70.png"
                  alt=""
              />
            </a>
          </div>
          <div class="text-2xl font-bold text-gray-400 px-4">
            ruhdacted
          </div>
        </div>
      </div>
    );
  }
}

class TwitchStats extends React.Component {
  render() {
    return (
      <div class="container mx-auto max-w-screen-md px-10 py-10 text-2xl">
        <h1 class="text-center">Twitch Stats</h1>
        <div class="grid grid-cols-2 place-content-center text-xl">
          <div>Follower Count:</div>
          <div class="place-self-end">62</div>
          <div>Subscription Count:</div>
          <div class="place-self-end">4</div>
          <div>Total Payout:</div>
          <div class="place-self-end -ml-5">$0</div>
          <div>Current Pending Payout:</div>
          <div class="place-self-end -ml-5">$89.99</div>
          <div>Total Equipment Expenses:</div>
          <div class="place-self-end -ml-5">$2,161.75</div>
          <div>Twitch Profit:</div>
          <div class="place-self-end -ml-8 text-red-600">($2,161.75)</div>
        </div>
      </div>
    );
  }
}

class LinkItem extends React.Component {
  render() {
    return (
      <div class="text-white">
        <a href={this.props.link} class="text-gray-400" target="_tab">{this.props.title}</a>
      </div>
    );
  }
}

class TwitchResources extends React.Component {
  render() {
    return (
      <div class="container mx-auto px-10 py-16 xl:py-0 text-lg">
        <h1 class="text-center text-3xl pb-16">Streaming Resources</h1>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6 lg:grid-cols-3">
          <div class="text-center md:text-left">
            <h3 class="text-2xl">Stream Tips</h3>
              <LinkItem title="TOP 5 Streaming Things I WISH I Knew When I Started" link="https://www.youtube.com/watch?v=1Dq5RAjpr5k" />
              <LinkItem title="Ludwig Streaming Tips" link="https://www.youtube.com/watch?v=0i9gkprYekI" />
              <LinkItem title="Alpha Gaming Channel" link="https://www.youtube.com/channel/UCATWC1JSlhzmYeDbjnS8WwA" />
              <LinkItem title="Devin Nash's Channel" link="https://www.youtube.com/channel/UCZ1fbizRtEOC3dbiFsVUaCQ" />
          </div>
          <div class="text-center md:text-left">
            <h3 class="text-xl">Streaming Software</h3>
            <LinkItem title="OBS Studio" link="https://obsproject.com/" />
            <LinkItem title="Streamlabs" link="https://streamlabs.com/" />
            <LinkItem title="XSplit" link="https://www.xsplit.com/" />
          </div>
          <div class="text-center md:text-left">
            <h3 class="text-xl">Streaming Tools</h3>
            <LinkItem title="StreamElements" link="https://streamelements.com/" />
            <LinkItem title="Twitch.guru" link="http://twitch.guru/" />
            <LinkItem title="Restream.io" link="https://restream.io/" />
          </div>
          <div class="text-center md:text-left">
            <h3 class="text-2xl">Audio Tools</h3>
              <LinkItem title="VoiceMeeter" link="https://vb-audio.com/Voicemeeter/index.htm" />
              <LinkItem title="NVIDIA Broadcast" link="https://www.nvidia.com/en-us/geforce/broadcasting/broadcast-app/" />
              <LinkItem title="Krisp" link="https://krisp.ai/" />
          </div>
          <div class="text-center md:text-left">
            <h3 class="text-2xl">Twitch Clip Converters</h3>
              <LinkItem title="Clips.JoinCombo.com" link="https://clips.joincombo.com/dashboard" />
              <LinkItem title="StreamLadder.com" link="https://streamladder.com/" />
          </div>
          <div class="text-center md:text-left">
            <h3 class="text-2xl">Social Numbers</h3>
              <LinkItem title="TwitchTracker" link="https://twitchtracker.com/" />
              <LinkItem title="SullyGnome" link="https://sullygnome.com/" />
              <LinkItem title="Social Blade" link="https://socialblade.com/" />
          </div>
          <div class="text-center md:text-left">
            <h3 class="text-2xl">Browser Extensions</h3>
              <LinkItem title="BetterTTV" link="https://betterttv.com/" />
              <LinkItem title="FrankerFaceZ" link="https://www.frankerfacez.com/" />
              <LinkItem title="vidIQ" link="https://vidiq.com/" />
              <LinkItem title="Keywords Everywhere" link="https://keywordseverywhere.com/" />
          </div>
          <div class="text-center md:text-left">
            <h3 class="text-2xl">Vertical Video Converters</h3>
            <LinkItem title="Tappable.co" link="https://tappable.co/vertical-video" />
            <LinkItem link="https://placeit.net/video-cropper" title="Placeit.net" />
            <LinkItem link="https://ezgif.com/crop-video" title="EZGif.com" />
            <LinkItem link="https://pixiko.com/editor-evolution" title="Pixiko.com" />
            <LinkItem link="https://clideo.com/editor/crop-video" title="Clideo.com" />
          </div>
          <div class="text-center md:text-left">
            <h3 class="text-2xl">Video Editing</h3>
            <LinkItem title="ShotCut" link="https://shotcut.org/download/" />
            <LinkItem title="Da Vinci Resolve" link="https://www.blackmagicdesign.com/products/davinciresolve/" />
            <LinkItem title="Primal Video Channel" link="https://www.youtube.com/channel/UCO4Nw0vUpxgb0zsziJ1SaMg" />
          </div>
          <div class="text-center md:text-left">
            <h3 class="text-2xl">Motivation - Gary Vee</h3>
            <LinkItem title="HOW TO START" link="https://www.youtube.com/watch?v=Q5jiifErFEE" />
            <LinkItem title="Gary Vee's Channel" link="https://www.youtube.com/channel/UCctXZhXmG-kf3tlIXgVZUlw" />
            <LinkItem title="How to Create 64 Pieces of Content in a Day" link="https://www.garyvaynerchuk.com/how-to-create-64-pieces-of-content-in-a-day/" />
          </div>
          <div class="text-center md:text-left">
            <h3 class="text-2xl">YouTube Content</h3>
            <LinkItem title="Paddy Galloway - YouTube Algorithm" link="https://www.youtube.com/channel/UClga3ybuyyjpvqsh-cf3DvQ" />
          </div>
        </div>
      </div>
    );
  }
}

class Socials extends React.Component {
  render() {
    return (
      <div class="container mx-auto px-10 py-10 text-2xl">
        <div class="grid grid-cols-1 md:grid-cols-5 gap-4 text-center">
          <LinkItem title="YouTube" link="https://www.youtube.com/channel/UCx2D8WAp2K43WlXQ7c51IVQ" />
          <LinkItem title="TikTok" link="https://www.tiktok.com/@ruhdacted" />
          <LinkItem title="Twitter" link="https://twitter.com/ruhdacted" />
          <LinkItem title="Instagram" link="https://www.instagram.com/ruhdacted/" />
        </div>
      </div>
    );
  }
}

class Firebase extends React.Component {
  render() {
    return (
      <div>
        <script src="/__/firebase/8.4.1/firebase-app.js"></script>
        <script src="/__/firebase/init.js"></script>
      </div>
    )
  }
}
function App() {
  return (
    <div className="App">
      <PageHeader />
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        {/* <div>
          <ReactTwitchEmbedVideo channel="bluddshed" layout="video" />
        </div> */}
        
      </header>
      {/* <div class="container mx-auto py-9 px-9 bg-gray-700">
        <CardInnerCard />
      </div> */}
      <div className="App-body">
        <div class="grid grid-cols-1 xl:grid-cols-2 xl:gap-x-16">
          <div class="container lg:max-w-screen-lg">
            <TwitchResources />
          </div>
          <div class="">
            <TwitchVideo />
            <TwitchStats />
          </div>
        </div>
      </div>
      <div className="footer" class="bg-gray-800">
        <Socials />
      </div>
      <Firebase />
    </div>
  );
}

export default App;
